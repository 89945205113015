
import { Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import ProductList from "@/components/user/list/ProductList.vue";
@Component({
  components: { ProductList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // csv
  @Action("csv/userProductExport")
  public getProductCsv!: () => Promise<boolean>;

  public async download_btn() {
    await this.getProductCsv();
  }
}
